import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { Box, Button, Dialog, DialogContent } from '@mui/material';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import Translate, { Localization } from '../../../localization/Localization';
import { ProgrammingStatus } from '../../../shared/components/ActionsMenu/models/ProgrammingStatus';
import { PageLoaderComponent } from '../../../shared/components/PageLoader/PageLoaderComponent';
import { Currencies } from '../../../shared/models/Currencies';
import { MeasurementSystemType } from '../../../shared/models/MeasurementSystemType';
import { Point } from '../../../shared/models/Point';
import ToastService from '../../../ToastService';
import BusinessMessages from '../../../utils/BusinessMessages';
import { MeasurementSystem } from '../../../utils/MeasurementSystem';
import { RouteComponentProps, withRouter } from '../../../withRouter';
import { ProjectVersion } from '../../Home/services/dataContracts/queryStack/ProjectVersion';
import { CostRatio } from '../../ProjectSettings/services/dataContracts/queryStack/CostRatio';
import { MergedProjectVersion } from '../../RoadsCondition/models/MergedProjectVersion';
import { RouteLocationStateModel } from '../../RoadsCondition/models/RouteLocationStateModel';
import { RoadsConditionAndScenariosShared } from '../../RoadsCondition/RoadsConditionAndScenariosShared';
import { Environment } from '../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Environment';
import { Hierarchy } from '../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Hierarchy';
import { Manager } from '../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Manager';
import { Traffic } from '../../RoadsCondition/services/RoadsCondition/dataContracts/queryStack/Traffic';
import { ProgrammingAreaExtended } from '../models/ProgrammingAreaExtended';
import { ProgrammingExtended } from '../models/ProgrammingExtended';
import { AddOrUpdateProgrammingAreaRequestArgs } from '../services/dataContracts/controller/AddOrUpdateProgrammingAreaRequestArgs';
import { DeleteProgrammingAreaRequestArgs } from '../services/dataContracts/controller/DeleteProgrammingAreaRequestArgs';
import { GetRoadWorksRequestArgs } from '../services/dataContracts/controller/GetRoadWorksRequestArgs';
import { FilteredProgramming } from '../services/dataContracts/queryStack/FilteredProgramming';
import { Programming } from '../services/dataContracts/queryStack/Programming';
import { ProgrammingArea } from '../services/dataContracts/queryStack/ProgrammingArea';
import { ProgrammingAreaStatus } from '../services/dataContracts/queryStack/ProgrammingAreaStatus';
import { WorkOrientation } from '../services/dataContracts/queryStack/WorkOrientation';
import { ProgrammingsApiClient } from '../services/ProgrammingsApiClient';
import './AreasManagementStyles.scss';
import { ActionsMenuComponent } from './components/ActionsMenuComponent';
import { AddOrEditAreaComponent } from './components/AddOrEditAreaComponent';
import { AreasListComponent } from './components/AreasListComponent';
import { AreasMapComponent } from './components/AreasMapComponent';
import { AreasSynthesisComponent } from './components/AreasSynthesisComponent';
import { HeaderComponent } from './components/HeaderComponent';
import { SectionsSelectorComponent } from './components/SectionsSelectorComponent';

interface AreasManagementViewState {
    loading: boolean,
    selectedProgramming: ProgrammingExtended,
    mergedProject: MergedProjectVersion,
    currentCurrency: string,
    isAddOrEditAreaActive: boolean,
    isZonesListOpened: boolean,
    selectedSectionsIds: Set<number>,
    typesOfWorks: string[],
    costRatios: CostRatio[],
    measurementSystemType: MeasurementSystemType,
    areaToEdit: ProgrammingArea,
    showProgrammingArea: ProgrammingAreaExtended,
    hideProgrammingArea: ProgrammingAreaExtended,
    activeAnomalies: Set<string>,
    activeQualities: Set<number>,
    activeMunicipalities: Set<string>,
    activeDistricts: Set<string>,
    activeCollaborativeDevelopmentZones: Set<string>,
    activeHierarchies: Set<Hierarchy>,
    activeTraffics: Set<Traffic>,
    activeEnvironments: Set<Environment>,
    activeManagers: Set<Manager>,
    activeImportances: Set<string>,
    activeOtherAttributes: Set<string>,
    inputSearchValue: string,
    isConfirmDeleteDialogOpened: boolean,
    programmingAreaIdToDelete: number,
    selectedProgrammings: string[],
    selectedYears: number[],
    activeStatus: Set<ProgrammingStatus>,
    filteredProgrammingsFromFilter: FilteredProgramming[]
}

const initialState: AreasManagementViewState = {
    loading: false,
    selectedProgramming: null,
    mergedProject: null,
    currentCurrency: null,
    isAddOrEditAreaActive: false,
    isZonesListOpened: true,
    selectedSectionsIds: new Set<number>(),
    typesOfWorks: [],
    costRatios: [],
    measurementSystemType: null,
    areaToEdit: null,
    showProgrammingArea: null,
    hideProgrammingArea: null,
    activeAnomalies: new Set<string>(),
    activeQualities: new Set<number>([]),
    activeMunicipalities: new Set<string>(),
    activeDistricts: new Set<string>(),
    activeCollaborativeDevelopmentZones: new Set<string>(),
    activeHierarchies: new Set<Hierarchy>(),
    activeTraffics: new Set<Traffic>(),
    activeEnvironments: new Set<Environment>(),
    activeManagers: new Set<Manager>(),
    activeImportances: new Set<string>(),
    activeOtherAttributes: new Set<string>(),
    inputSearchValue: '',
    isConfirmDeleteDialogOpened: false,
    programmingAreaIdToDelete: null,
    selectedProgrammings: [],
    selectedYears: [],
    activeStatus: new Set<ProgrammingStatus>(),
    filteredProgrammingsFromFilter: null
}

export class AreasManagementView extends Component<RouteComponentProps, AreasManagementViewState> {
    _isMounted: boolean;
    projectId: string;
    locationGeometry: Point;
    mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>;
    projectVersionsCache: Map<number, ProjectVersion>;
    inputSearchRoadsRef: React.RefObject<HTMLInputElement>;

    constructor(props) {
        super(props);

        this.mergedProjectAuscultationsCache = new Map<number, MergedProjectVersion>();
        this.projectVersionsCache = new Map<number, ProjectVersion>();
        this.inputSearchRoadsRef = React.createRef();

        initialState.activeQualities = RoadsConditionAndScenariosShared.getInitialActiveQualities();
        initialState.measurementSystemType = MeasurementSystem.getCurrentType();

        this.state = initialState;
    }

    async componentDidMount() {
        this._isMounted = true;

        let locationState = this.props.location.state as RouteLocationStateModel;
        if (!locationState) {
            setTimeout(() => this.props.navigate("/"));
            return;
        }

        this.projectId = locationState.projectId;
        this.locationGeometry = locationState.locationGeometry;

        const query = new URLSearchParams(this.props.location.search);
        const programmingId = Number(query.get('programmingId'));

        this.setState({
            loading: true
        });

        const programmingData = await ProgrammingsApiClient.GetProgramming(this.projectId, programmingId);
        let programming = programmingData.data;

        await Promise.all([
            RoadsConditionAndScenariosShared.getMergedProject(programming.projectVersionId, this.mergedProjectAuscultationsCache, this.projectVersionsCache),
            ProgrammingsApiClient.GetProjectCurrency(this.projectId),
            ProgrammingsApiClient.GetTypesOfWorks(this.projectId)
        ])
            .then(async (results) => {
                let mergedProject = results[0];
                let currency = results[1].data;
                let selectedProgramming = this.buildExtendedProgramming(programming, mergedProject);
                let typesOfWorks = results[2].data;

                await ProgrammingsApiClient.GetCostRatios(this.projectId, programming.year)
                    .then(res => {
                        let costRatios = res.data;

                        this.setState({
                            loading: false,
                            selectedProgramming: selectedProgramming,
                            mergedProject: mergedProject,
                            currentCurrency: Currencies[currency],
                            typesOfWorks: typesOfWorks,
                            costRatios: costRatios
                        });
                    });
            });
    }

    handleCancel = (): void => {
        let urlRedirect = `/ProgrammingsManagement`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleAddZone = (): void => {
        this.setState({
            isAddOrEditAreaActive: true,
            isZonesListOpened: false
        })
    }

    handleAddOrEditAreaCanceled = (): void => {
        this.inputSearchRoadsRef.current.value = "";
        this.setState({
            isAddOrEditAreaActive: false,
            areaToEdit: null,
            selectedSectionsIds: new Set<number>(),
            inputSearchValue: "",
            isZonesListOpened: true,
            activeAnomalies: new Set<string>(),
            activeQualities: RoadsConditionAndScenariosShared.getInitialActiveQualities(),
            activeMunicipalities: new Set<string>(),
            activeDistricts: new Set<string>(),
            activeCollaborativeDevelopmentZones: new Set<string>(),
            activeHierarchies: new Set<Hierarchy>(),
            activeTraffics: new Set<Traffic>(),
            activeEnvironments: new Set<Environment>(),
            activeManagers: new Set<Manager>(),
            activeImportances: new Set<string>(),
            activeOtherAttributes: new Set<string>()
        })
    }

    handleSelectedSectionsIdsChanged = (selectedSectionsIds: Set<number>): void => {
        this.setState({
            selectedSectionsIds: selectedSectionsIds
        })
    }

    onSelectedSectionChange = (sectionsIds: number[]): void => {
        let selectedSectionsIds = new Set<number>(sectionsIds);
        this.setState({
            selectedSectionsIds: selectedSectionsIds,
        });
    }

    handleAddOrUpdateProgrammingArea = async (areaLabel: string,
        status: ProgrammingAreaStatus,
        workOrientation: WorkOrientation,
        selectedWork: string,
        hexColor: string,
        startDate: Date,
        endDate: Date,
        remarks: string,
        costRatio: number,
        costRatioIsOverriden: boolean,
        budgetAmount: number,
        lengthInLinearMeters: number,
        lengthInLinearMetersIsOverridden: boolean,
        areaInSquareMeters: number,
        areaInSquareMetersIsOverridden: boolean,
        state: AreasManagementViewState,
        programmingAreaId?: number): Promise<void> => {
        let programmingId = state.selectedProgramming.programmingId;
        let args: AddOrUpdateProgrammingAreaRequestArgs = {
            programmingAreaId: programmingAreaId,
            programmingId: programmingId,
            label: areaLabel,
            hexColor: hexColor,
            status: status,
            workOrientation: workOrientation,
            selectedWork: selectedWork,
            costRatio: costRatio,
            costRatioIsOverriden: costRatioIsOverriden,
            budgetAmount: budgetAmount,
            lengthInLinearMeters: lengthInLinearMeters,
            lengthInLinearMetersIsOverridden: lengthInLinearMetersIsOverridden,
            areaInSquareMeters: areaInSquareMeters,
            areaInSquareMetersIsOverridden: areaInSquareMetersIsOverridden,
            startDate: startDate,
            endDate: endDate,
            sectionsId: Array.from(state.selectedSectionsIds),
            remarks: remarks,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        this.setState({
            loading: true
        });

        await ProgrammingsApiClient.AddOrUpdateProgrammingArea(args)
            .then(async (res) => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false
                    });

                    return;
                }

                await Promise.all([
                    ProgrammingsApiClient.GetProgramming(this.projectId, programmingId),
                    ProgrammingsApiClient.GetTypesOfWorks(this.projectId)
                ])
                    .then((results) => {
                        this.inputSearchRoadsRef.current.value = "";
                        this.setState({
                            loading: false,
                            isAddOrEditAreaActive: false,
                            selectedProgramming: this.buildExtendedProgramming(results[0].data, state.mergedProject),
                            areaToEdit: null,
                            selectedSectionsIds: new Set<number>(),
                            typesOfWorks: results[1].data,
                            inputSearchValue: "",
                            isZonesListOpened: true,
                            activeAnomalies: new Set<string>(),
                            activeQualities: RoadsConditionAndScenariosShared.getInitialActiveQualities(),
                            activeMunicipalities: new Set<string>(),
                            activeDistricts: new Set<string>(),
                            activeCollaborativeDevelopmentZones: new Set<string>(),
                            activeHierarchies: new Set<Hierarchy>(),
                            activeTraffics: new Set<Traffic>(),
                            activeEnvironments: new Set<Environment>(),
                            activeManagers: new Set<Manager>(),
                            activeImportances: new Set<string>(),
                            activeOtherAttributes: new Set<string>()
                        });
                    });
            });
    }

    handleEditArea = (programmingAreaId: number, state: AreasManagementViewState): void => {
        let area = state.selectedProgramming.areas.find(x => x.programmingAreaId === programmingAreaId);
        this.setState({
            areaToEdit: area,
            isAddOrEditAreaActive: true,
            isZonesListOpened: false,
            selectedSectionsIds: new Set<number>(area.sections.map(x => x.roadSectionId))
        });
    }

    handleOpenConfirmDeleteAreaDialog = (programmingAreaId: number): void => {
        this.setState({
            isConfirmDeleteDialogOpened: true,
            programmingAreaIdToDelete: programmingAreaId
        })
    }

    handleDeleteAreaCanceled = (): void => {
        this.setState({
            isConfirmDeleteDialogOpened: false,
            programmingAreaIdToDelete: null
        })
    }

    handleDeleteArea = async (programmingAreaId: number, state: AreasManagementViewState): Promise<void> => {
        let programmingId = state.selectedProgramming.programmingId;
        let requestArgs: DeleteProgrammingAreaRequestArgs = {
            programmingId: state.selectedProgramming.programmingId,
            programmingAreaId: programmingAreaId,
            ianaTimeZoneId: Localization.ianaTimeZoneId
        };

        this.setState({
            loading: true
        });

        await ProgrammingsApiClient.DeleteProgrammingArea(requestArgs)
            .then(async (res) => {
                let data = res.data;
                let errors = BusinessMessages.GetErrors(data);
                if (errors.length > 0) {
                    ToastService.showErrorToast("", errors);

                    this.setState({
                        loading: false,
                        isConfirmDeleteDialogOpened: false,
                        programmingAreaIdToDelete: null
                    });

                    return;
                }

                await ProgrammingsApiClient.GetProgramming(this.projectId, programmingId)
                    .then((res) => {
                        this.setState({
                            loading: false,
                            selectedProgramming: this.buildExtendedProgramming(res.data, state.mergedProject),
                            isConfirmDeleteDialogOpened: false,
                            programmingAreaIdToDelete: null,
                            isAddOrEditAreaActive: false,
                            areaToEdit: null,
                            selectedSectionsIds: new Set<number>(),
                            isZonesListOpened: true
                        });
                    });
            });
    }

    handleDisplayAreaSections = (programmingAreaId: number, state: AreasManagementViewState) => {
        let selectedProgramming = state.selectedProgramming;
        let relatedAreas = selectedProgramming.relatedAreas;
        let areaIndex = relatedAreas.findIndex(x => x.programmingAreaId === programmingAreaId);
        relatedAreas[areaIndex].isEnlightened = true;
        selectedProgramming.relatedAreas = relatedAreas;
        this.setState({
            showProgrammingArea: relatedAreas[areaIndex],
            selectedProgramming: selectedProgramming
        });
    }

    handleHideAreaSections = (programmingAreaId: number, state: AreasManagementViewState) => {
        let selectedProgramming = state.selectedProgramming;
        let relatedAreas = selectedProgramming.relatedAreas;
        let areaIndex = relatedAreas.findIndex(x => x.programmingAreaId === programmingAreaId);
        relatedAreas[areaIndex].isEnlightened = false;
        selectedProgramming.relatedAreas = relatedAreas;

        this.setState({
            hideProgrammingArea: relatedAreas[areaIndex],
            selectedProgramming: selectedProgramming
        });
    }

    reInitDisplayedProgrammingArea = (): void => {
        this.setState({
            showProgrammingArea: null
        });
    }

    reInitHidenProgrammingArea = (): void => {
        this.setState({
            hideProgrammingArea: null
        });
    }

    buildExtendedProgramming = (programming: Programming, mergedProject: MergedProjectVersion): ProgrammingExtended => {
        let extendedProgramming = { ...programming } as ProgrammingExtended;
        let extendedAreas: ProgrammingAreaExtended[] = [];
        programming.areas.forEach((area) => {

            let monitoringSectionsLengthInMeters: number = 0;
            let localizedRepairSectionsLengthInMeters: number = 0;
            let generalMaintenanceSectionsLengthInMeters: number = 0;
            let reinforcementSectionsLengthInMeters: number = 0;
            let rehabilitationSectionsLengthInMeters: number = 0;

            let weightedScoreSum = 0;
            let sumOfLengthInMeters = 0;

            area.sections.map(x => x.roadSectionId).forEach((sectionId) => {
                let section = mergedProject.roadsSections.get(sectionId);
                let lengthInMeters = Math.round(section.lengthInMeters);

                if (section.score === 10 || section.score === 9 || section.score === 8) {
                    monitoringSectionsLengthInMeters += lengthInMeters;
                }

                if (section.score === 7 || section.score === 6) {
                    localizedRepairSectionsLengthInMeters += lengthInMeters;
                }

                if (section.score === 5 || section.score === 4) {
                    generalMaintenanceSectionsLengthInMeters += lengthInMeters;
                }

                if (section.score === 3 || section.score === 2) {
                    reinforcementSectionsLengthInMeters += lengthInMeters;
                }

                if (section.score === 1) {
                    rehabilitationSectionsLengthInMeters += lengthInMeters;
                }

                weightedScoreSum = lengthInMeters && section.score ? lengthInMeters * section.score + (weightedScoreSum ?? 0) : weightedScoreSum ?? 0;
                sumOfLengthInMeters += (lengthInMeters ?? 0);
            });

            let extendedArea: ProgrammingAreaExtended = {
                ...area,
                isEnlightened: false,
                inEditField: undefined,
                monitoringSectionsPercent: sumOfLengthInMeters ? (monitoringSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0,
                localizedRepairSectionsPercent: sumOfLengthInMeters ? (localizedRepairSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0,
                generalMaintenanceSectionsPercent: sumOfLengthInMeters ? (generalMaintenanceSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0,
                reinforcementSectionsPercent: sumOfLengthInMeters ? (reinforcementSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0,
                rehabilitationSectionsPercent: sumOfLengthInMeters ? (rehabilitationSectionsLengthInMeters * 100) / sumOfLengthInMeters : 0,
                averageScore: sumOfLengthInMeters ? weightedScoreSum / sumOfLengthInMeters : 0,
                expanded: false,
                sectionsId: area.sections.map(x => x.roadSectionId)
            }
            extendedAreas.push(extendedArea);
        });

        extendedProgramming.relatedAreas = extendedAreas;
        return extendedProgramming;
    }

    handleUpdateProgrammingClicked = (state: AreasManagementViewState): void => {
        let urlRedirect = `/ProgrammingsManagement?programmingId=${state.selectedProgramming.programmingId}`;
        let locationState = this.props.location.state as RouteLocationStateModel;
        this.props.navigate(urlRedirect, { state: locationState });
    }

    handleZonesListClosed = (): void => {
        this.setState({
            isZonesListOpened: false
        })
    }

    handleZonesListOpened = (): void => {
        this.setState({
            isZonesListOpened: true
        })
    }

    handleChangeRoadsSearchText = debounce((value: string): void => {
        let inputValue = value;
        if (inputValue.length > 2) {
            this.setState({
                inputSearchValue: inputValue.trim().toLowerCase().removeDiacritics()
            })
        }
        else if (inputValue.length === 0) {
            this.setState({
                inputSearchValue: ""
            })
        }
    }, 500);

    handleDisplayDetections = (activeAnomalies: Set<string>): void => {
        this.setState({
            activeAnomalies: activeAnomalies
        });
    }

    handleDisplaySectionsFromQualityFilters = (activeQualities: Set<number>): void => {
        this.setState({
            activeQualities: activeQualities
        });
    }

    handleDisplaySectionsFromGeographiesFilters = (activeMunicipalities: Set<string>, activeDistricts: Set<string>, activeCollaborativeDevelopmentZones: Set<string>): void => {
        this.setState({
            activeMunicipalities: activeMunicipalities,
            activeDistricts: activeDistricts,
            activeCollaborativeDevelopmentZones: activeCollaborativeDevelopmentZones
        });
    }

    handleDisplaySectionsFromAttributesFilters = (activeHierarchies: Set<Hierarchy>, activeTraffics: Set<Traffic>, activeEnvironments: Set<Environment>, activeManagers: Set<Manager>, activeImportances: Set<string>, activeOtherAttributes: Set<string>): void => {
        this.setState({
            activeHierarchies: activeHierarchies,
            activeTraffics: activeTraffics,
            activeEnvironments: activeEnvironments,
            activeManagers: activeManagers,
            activeImportances: activeImportances,
            activeOtherAttributes: activeOtherAttributes
        });
    }

    handleMeasurementSystemTypeChanged = (measurementSystemType: MeasurementSystemType): void => {
        this.setState({
            measurementSystemType
        });
    }

    handleDisplayAreasFromWorksFilter = (selectedProgrammings: string[], selectedYears: number[], activeStatus: Set<ProgrammingStatus>): void => {
        this.setState({
            loading: true
        });

        let status: number[] = Array.from(activeStatus).map(x => {
            return x === ProgrammingStatus.finished ? 2 : (x === ProgrammingStatus.toBeCompleted ? 1 : null);
        });

        let data: GetRoadWorksRequestArgs = {
            years: selectedYears,
            labels: selectedProgrammings,
            status
        };

        ProgrammingsApiClient.GetRoadWorksByFilters(this.projectId, data)
            .then((res) => {
                this.setState({
                    filteredProgrammingsFromFilter: res.data,
                    selectedProgrammings,
                    selectedYears,
                    activeStatus,
                    loading: false
                });
            });
    }

    render() {
        const state = this.state;

        return (
            <Box className="areas-management">
                {this.state.loading ? <PageLoaderComponent /> : null}
                <HeaderComponent loading={state.loading} selectedProgramming={state.selectedProgramming} handleCancel={() => this.handleCancel()} handleUpdateProgrammeClicked={() => this.handleUpdateProgrammingClicked(state)} />
                <ActionsMenuComponent
                    inputRef={this.inputSearchRoadsRef}
                    projectId={this.projectId}
                    activeAnomalies={state.activeAnomalies}
                    activeQualities={state.activeQualities}
                    activeMunicipalities={state.activeMunicipalities}
                    activeDistricts={state.activeDistricts}
                    activeCollaborativeDevelopmentZones={state.activeCollaborativeDevelopmentZones}
                    activeHierarchies={state.activeHierarchies}
                    activeTraffics={state.activeTraffics}
                    activeEnvironments={state.activeEnvironments}
                    activeManagers={state.activeManagers}
                    activeImportances={state.activeImportances}
                    activeOtherAttributes={state.activeOtherAttributes}
                    mergedProject={state.mergedProject}
                    loading={state.loading}
                    isDisabled={!state.isAddOrEditAreaActive}
                    isZonesListOpened={state.isZonesListOpened}
                    selectedProgrammings={state.selectedProgrammings}
                    selectedYears={state.selectedYears}
                    activeStatus={state.activeStatus}
                    selectedProgramming={state.selectedProgramming}
                    handleZonesListClosed={this.handleZonesListClosed}
                    handleZonesListOpened={this.handleZonesListOpened}
                    handleSearchTextChanged={(value) => this.handleChangeRoadsSearchText(value)}
                    handleDisplayDetections={this.handleDisplayDetections}
                    handleDisplaySections={this.handleDisplaySectionsFromQualityFilters}
                    handleDisplaySectionsFromGeographiesFilters={this.handleDisplaySectionsFromGeographiesFilters}
                    handleDisplaySectionsFromAttributesFilters={this.handleDisplaySectionsFromAttributesFilters}
                    handleDisplayAreasFromWorksFilter={this.handleDisplayAreasFromWorksFilter}
                />
                {this.locationGeometry &&
                    <Box className={`map-with-sections-content ${state.isAddOrEditAreaActive ? 'in-edit' : ''}`}>
                        {state.isAddOrEditAreaActive &&
                            <SectionsSelectorComponent
                                mergedProject={state.mergedProject}
                                selectedSectionsIds={state.selectedSectionsIds}
                                activeAnomalies={state.activeAnomalies}
                                activeQualities={state.activeQualities}
                                activeMunicipalities={state.activeMunicipalities}
                                activeDistricts={state.activeDistricts}
                                activeCollaborativeDevelopmentZones={state.activeCollaborativeDevelopmentZones}
                                activeHierarchies={state.activeHierarchies}
                                activeTraffics={state.activeTraffics}
                                activeEnvironments={state.activeEnvironments}
                                activeManagers={state.activeManagers}
                                activeImportances={state.activeImportances}
                                activeOtherAttributes={state.activeOtherAttributes}
                                inputSearchValue={state.inputSearchValue}
                                handleSelectedSectionsIdsChanged={this.handleSelectedSectionsIdsChanged}
                            />
                        }
                        <AreasMapComponent
                            isAddOrEditAreaActive={state.isAddOrEditAreaActive}
                            isZonesListOpened={state.isZonesListOpened}
                            locationGeometry={this.locationGeometry}
                            mergedProject={state.mergedProject}
                            activeAnomalies={state.activeAnomalies}
                            activeQualities={state.activeQualities}
                            activeMunicipalities={state.activeMunicipalities}
                            activeDistricts={state.activeDistricts}
                            activeCollaborativeDevelopmentZones={state.activeCollaborativeDevelopmentZones}
                            activeHierarchies={state.activeHierarchies}
                            activeTraffics={state.activeTraffics}
                            activeEnvironments={state.activeEnvironments}
                            activeManagers={state.activeManagers}
                            activeImportances={state.activeImportances}
                            activeOtherAttributes={state.activeOtherAttributes}
                            inputSearchValue={state.inputSearchValue}
                            loading={state.loading}
                            selectedProgramming={state.selectedProgramming}
                            filteredProgrammingsFromFilter={state.filteredProgrammingsFromFilter}
                            showProgrammingArea={state.showProgrammingArea}
                            hideProgrammingArea={state.hideProgrammingArea}
                            selectedSectionsIds={state.selectedSectionsIds}
                            reInitDisplayedProgrammingArea={this.reInitDisplayedProgrammingArea}
                            reInitHidenProgrammingArea={this.reInitHidenProgrammingArea}
                            onSelectedSectionChange={(sectionsIds) => this.onSelectedSectionChange(sectionsIds)}
                            currentMeasurementSystemType={state.measurementSystemType}
                        />
                    </Box>
                }

                {!state.isAddOrEditAreaActive &&
                    <Box display="flex" flexDirection="column">
                        <AreasSynthesisComponent loading={state.loading} selectedProgramming={state.selectedProgramming} currency={state.currentCurrency} mergedProject={state.mergedProject} handleAddZoneCLicked={() => this.handleAddZone()} />
                        {state.isZonesListOpened &&
                            <AreasListComponent areas={state.selectedProgramming?.relatedAreas}
                                currency={state.currentCurrency}
                                mergedProject={state.mergedProject}
                                costRatios={state.costRatios}
                                handleEditArea={(programmingAreaId: number) => this.handleEditArea(programmingAreaId, state)}
                                handleDeleteArea={(programmingAreaId: number) => this.handleOpenConfirmDeleteAreaDialog(programmingAreaId)}
                                handleDisplayAreaSections={(programmingAreaId: number) => this.handleDisplayAreaSections(programmingAreaId, state)}
                                handleHideAreaSections={(programmingAreaId: number) => this.handleHideAreaSections(programmingAreaId, state)}
                            />
                        }
                    </Box>
                }

                {state.isAddOrEditAreaActive &&
                    <AddOrEditAreaComponent isNew={state.areaToEdit !== null ? false : true}
                        areaToEdit={state.areaToEdit}
                        mergedProject={state.mergedProject}
                        currency={state.currentCurrency}
                        typesOfWorks={state.typesOfWorks}
                        costRatios={state.costRatios}
                        selectedSectionsIds={state.selectedSectionsIds}
                        handleAddOrUpdateProgrammingArea={(areaLabel: string,
                            status: ProgrammingAreaStatus,
                            workOrientation: WorkOrientation,
                            selectedWork: string,
                            hexColor: string,
                            startDate: Date,
                            endDate: Date,
                            remarks: string,
                            costRatio: number,
                            costRatioIsOverriden: boolean,
                            budgetAmount: number,
                            lengthInLinearMeters: number,
                            lengthInLinearMetersIsOverridden: boolean,
                            areaInSquareMeters: number,
                            areaInSquareMetersIsOverridden: boolean,
                            programmingAreaId?: number) => this.handleAddOrUpdateProgrammingArea(areaLabel,
                                status,
                                workOrientation,
                                selectedWork,
                                hexColor,
                                startDate,
                                endDate,
                                remarks,
                                costRatio,
                                costRatioIsOverriden,
                                budgetAmount,
                                lengthInLinearMeters,
                                lengthInLinearMetersIsOverridden,
                                areaInSquareMeters,
                                areaInSquareMetersIsOverridden,
                                state,
                                programmingAreaId)}
                        handleCancel={() => this.handleAddOrEditAreaCanceled()}
                        handleDeleteArea={(programmingAreaId: number) => this.handleOpenConfirmDeleteAreaDialog(programmingAreaId)} />
                }
                {state.isConfirmDeleteDialogOpened &&
                    <Dialog id="delete-area-dialog" open={state.isConfirmDeleteDialogOpened}>
                        <DialogContent>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <ReportProblemIcon className="warning-icon" />
                                <Box className="text">
                                    {Translate.Resources.UI_Programming_AddOrEditArea_AreYouSureYouWantToRemoveThisZone}
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="flex-end">
                                    <Button className="btn-secondary" onClick={this.handleDeleteAreaCanceled}>
                                        {Translate.Resources.UI_Programming_AreasManagement_Cancel}
                                    </Button>
                                    <Button className="btn-primary" onClick={() => this.handleDeleteArea(state.programmingAreaIdToDelete, state)}>
                                        {Translate.Resources.UI_Programming_AreasManagement_Delete}
                                    </Button>
                                </Box>
                            </Box>
                        </DialogContent>
                    </Dialog>
                }
            </Box>
        );
    }
}

export default React.forwardRef(withRouter(AreasManagementView));
