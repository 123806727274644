import { Box, Button, Checkbox, ClickAwayListener, FormControlLabel, Popper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Translate from '../../../localization/Localization';
import { MergedProjectVersion } from '../models/MergedProjectVersion';
import './DetectionListFilterStyles.scss';

interface DetectionItemProps {
    label: string,
    isActive: boolean,
    anomaly: string,
    onChange: (anomaly: string, isActive: boolean) => void
}

const DetectionItem = (props: DetectionItemProps): JSX.Element => {
    return (
        <Box className="detection-item">
            <FormControlLabel
                control={<Checkbox checked={props.isActive} onChange={() => props.onChange(props.anomaly, props.isActive)} />}
                label={
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <div className={`detection-color ${props.anomaly}`}></div>
                        {props.label}
                    </Box>
                }
                className="form-control-label-checkbox" />
        </Box>
    );
}

interface DetectionListFilterComponentProps {
    open: boolean,
    anchorEl: null | HTMLElement,
    activeAnomalies: Set<string>,
    mergedProject: MergedProjectVersion,
    onClose: () => void,
    handleDisplayDetection: (activeAnomalies: Set<string>) => void
}

export const DetectionListFilterComponent = (props: DetectionListFilterComponentProps): JSX.Element => {

    const [activeAnomalies, setActiveAnomalies] = useState<Set<string>>(new Set<string>(props.activeAnomalies));

    const [anomaliesEnteringScore, setAnomaliesEnteringScore] = useState<Map<string, string>>(new Map<string, string>());
    const [anomaliesOther, setAnomaliesOther] = useState<Map<string, string>>(new Map<string, string>());

    useEffect(() => {
        if (props.mergedProject) {
            let enteringScoreAnomalies = new Map<string, string>();
            props.mergedProject.trustedAnomaliesEnteringScore.forEach((anomaly) => {
                enteringScoreAnomalies.set(anomaly, Translate.GetAnomalyTypeLabel(anomaly));
            });
            enteringScoreAnomalies = new Map(Array.from(enteringScoreAnomalies).sort((a, b) => a[1].localeCompare(b[1])));
            setAnomaliesEnteringScore(enteringScoreAnomalies);

            let otherAnomalies = new Map<string, string>();
            props.mergedProject.trustedAnomaliesOther.forEach((anomaly) => {
                otherAnomalies.set(anomaly, Translate.GetAnomalyTypeLabel(anomaly));
            });
            otherAnomalies = new Map(Array.from(otherAnomalies).sort((a, b) => a[1].localeCompare(b[1])));
            setAnomaliesOther(otherAnomalies);
        }
    }, [props.mergedProject]);

    const handleAnomalyChanged = (anomalyType: string, isActive: boolean): void => {
        let activeAnomaliesSet = new Set(activeAnomalies);
        if (isActive) {
            activeAnomaliesSet.delete(anomalyType);
        }
        else {
            activeAnomaliesSet.add(anomalyType);
        }
        setActiveAnomalies(activeAnomaliesSet);
    }

    const handleCheckAllDetections = (checked: boolean): void => {
        if (!checked) {
            setActiveAnomalies(new Set<string>());
            return;
        }

        let activeAnomaliesSet = new Set(activeAnomalies);
        anomaliesEnteringScore.forEach((value, key) => {
            if (!activeAnomaliesSet.has(key))
                activeAnomaliesSet.add(key);
        });

        anomaliesOther.forEach((value, key) => {
            if (!activeAnomaliesSet.has(key))
                activeAnomaliesSet.add(key);
        });

        setActiveAnomalies(activeAnomaliesSet);
    }

    const handleValidate = (): void => {
        props.handleDisplayDetection(activeAnomalies);
        props.onClose();
    }

    return (
        <ClickAwayListener onClickAway={props.onClose}>
            <Popper open={props.open} anchorEl={props.anchorEl} className="poper-menu detection-list-filter">
                <Box display="flex" flexDirection="column" width="100%">
                    <Box className="popper-content">
                        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                            <Box className="detections-header">
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={activeAnomalies.size === (anomaliesEnteringScore.size + anomaliesOther.size)}
                                            indeterminate={activeAnomalies.size > 0 && activeAnomalies.size < (anomaliesEnteringScore.size + anomaliesOther.size)}
                                            onChange={(e) => handleCheckAllDetections(e.target.checked)}
                                        />}
                                    label={
                                        <Box className="label">
                                            <span className="popper-title">{Translate.Resources.UI_ActionsMenu_Detection_TypeOfDamage}</span>
                                            <Box className={`counter ${activeAnomalies.size > 0 ? 'filled' : ''}`}>{activeAnomalies.size > 0 ? activeAnomalies.size : ''}</Box>
                                        </Box>
                                    }
                                    className="form-control-label-checkbox" />
                            </Box>
                        </Box>
                        <Box className="detection-list">
                            {Array.from(anomaliesEnteringScore).map((anomaly, index) => {
                                let isActive = activeAnomalies.has(anomaly[0]);
                                return (
                                    <DetectionItem key={`detection-item-${index}`}
                                        label={anomaly[1]}
                                        isActive={isActive}
                                        anomaly={anomaly[0]}
                                        onChange={() => handleAnomalyChanged(anomaly[0], isActive)}
                                    />
                                );
                            })}
                            {Array.from(anomaliesOther).map((anomaly, index) => {
                                let isActive = activeAnomalies.has(anomaly[0]);
                                return (
                                    <DetectionItem key={`detection-item-${index}`}
                                        label={anomaly[1]}
                                        isActive={isActive}
                                        anomaly={anomaly[0]}
                                        onChange={() => handleAnomalyChanged(anomaly[0], isActive)}
                                    />
                                );
                            })}
                        </Box>
                        <Box className="btns-actions">
                            <Button className="btn-cancel" onClick={() => props.onClose()}>
                                {Translate.Resources.UI_Global_Cancel}
                            </Button>
                            <Button className="btn-validate" onClick={() => handleValidate()}>
                                {Translate.Resources.UI_Global_Validate}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Popper>
        </ClickAwayListener>
    );
}