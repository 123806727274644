import { Box } from '@mui/system';
import { AnimationOptions, CameraBoundsOptions, data, layer, Map as AzureMap, MapMouseEvent, Popup, Shape, source } from 'azure-maps-control';
import { isEqual } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { barAction, MapActionBar } from '../../../../../shared/components/MapActionBar/MapActionBar';
import { ScaleLoaderComponent } from '../../../../../shared/components/ScaleLoader/ScaleLoaderComponent';
import { areaWidth, cameraAnimationDuration, cameraAnimationType, createMap, createShape, getAreaTooltip, getMapChoiceValue, getSectionShapeId, getSelectedSectionShapeId, hideSectionSelectedShape, sectionWidth, selectedSectionWidth, setMapCameraFromPositions, setMapCursor, showSectionSelectedShape, transparentColor } from '../../../../../shared/Map/MapUtils';
import { MapCursorMode } from '../../../../../shared/models/MapCursorMode';
import { MeasurementSystemType } from '../../../../../shared/models/MeasurementSystemType';
import { Point } from '../../../../../shared/models/Point';
import { ShapeEntityType } from '../../../../../shared/models/ShapeEntityType';
import styles from '../../../../../_variables.scss';
import styles2 from '../../../../../_variables2.scss';
import { FilteredProgramming } from '../../../../Programmings/services/dataContracts/queryStack/FilteredProgramming';
import { MergedProjectVersion } from '../../../../RoadsCondition/models/MergedProjectVersion';
import { RoadSectionViewData } from '../../../../RoadsCondition/models/RoadSectionViewData';
import { RoadStepViewData } from '../../../../RoadsCondition/models/RoadStepViewData';
import { ScoreTypesColors } from '../../../../RoadsCondition/models/ScoreTypesColors';
import { mainDatasourceId, roadLayerId, RoadsConditionAndScenariosShared } from '../../../../RoadsCondition/RoadsConditionAndScenariosShared';
import './MaintenanceScenarioMapStyles.scss';

interface MaintenanceScenarioMapComponentProps {
    locationGeometry: Point,
    mergedProject: MergedProjectVersion,
    filterdAndSelectedSectionsPositions: data.Position[],
    filterdSectionsIds: Set<number>,
    selectedSectionsIds: Set<number>,
    activeAnomalies: Set<string>,
    loading: boolean,
    isSectionsDrawerOpened: boolean,
    onSelectedSectionChange: (sectionsIds: number[]) => void,
    currentMeasurementSystemType: MeasurementSystemType,
    filteredProgrammingsFromFilter: FilteredProgramming[]
}

export const MaintenanceScenarioMapComponent = (props: MaintenanceScenarioMapComponentProps): JSX.Element => {

    const azureMap = useRef<AzureMap>();
    const areaTooltipPopup: Popup = new Popup({ closeButton: false });

    const filteredAreasDatasource = "filteredAreasDatasource";
    const filteredArea = "filteredArea";
    const filteredAreasRoadLayerId = "filteredAreasRoadLayerId";

    const anomalyPointClickHandler: (e: void | MapMouseEvent | layer.Layer) => void = null;

    const [selectedSectionsId, setSelectedSectionsId] = React.useState<number[]>([]);
    const [activeAnomalies, setActiveAnomalies] = React.useState<Set<string>>(new Set<string>());

    useEffect(() => {
        if (!azureMap.current) {
            let mapChoice = getMapChoiceValue();
            azureMap.current = createMap('AzureMap', 4, props.locationGeometry, mapChoice);
        }

        if (props.mergedProject) {
            initMap(azureMap.current, () => {
                createMapSectionsShapes(props.mergedProject, props.selectedSectionsIds);
                setMapZoom(azureMap.current, props.mergedProject);
            });
        }
    }, [props.mergedProject]);

    useEffect(() => {
        if (props.filterdAndSelectedSectionsPositions.length > 0) {
            setMapCameraFromPositions(azureMap.current, props.filterdAndSelectedSectionsPositions);
        }
        else if (props.filterdAndSelectedSectionsPositions.length === 0 && props.mergedProject) {
            setMapZoom(azureMap.current, props.mergedProject);
        }
    }, [props.filterdAndSelectedSectionsPositions]);

    useEffect(() => {
        if (props.selectedSectionsIds) {
            let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
            if (datasource) {
                let unselectedSection = selectedSectionsId.filter(x => !props.selectedSectionsIds.has(x));

                unselectedSection.forEach((sectionId: number) => {
                    hideSectionSelectedShape(datasource, sectionId);
                });

                props.selectedSectionsIds.forEach((sectionId: number) => {
                    showSectionSelectedShape(datasource, sectionId);
                });
            }

            setSelectedSectionsId(Array.from(props.selectedSectionsIds.keys()));

            if (props.mergedProject) {
                if (props.selectedSectionsIds.size > 0) {
                    RoadsConditionAndScenariosShared.setMapCameraFromPosition(azureMap.current, props.selectedSectionsIds, props.mergedProject.roadsSections);
                }
                else {
                    setMapZoom(azureMap.current, props.mergedProject);
                }
            }
        }
    }, [props.selectedSectionsIds]);

    useEffect(() => {
        if (!isEqual(props.activeAnomalies, activeAnomalies)) {
            let hasAnomaliesLayerMapEvent = true;
            let anomaliesDatasource = RoadsConditionAndScenariosShared.recreateAnomaliesDatasource(azureMap.current, props.mergedProject, hasAnomaliesLayerMapEvent, anomalyLayerMouseover, anomalyLayerMouseout, anomalyPointClickHandler, handleAnomalyPointClicked);

            if (props.activeAnomalies.size > 0) {
                props.mergedProject.roadsSteps.forEach((step: RoadStepViewData) => {
                    let scoringParameters = step.scoringParameters;
                    let anomalies = RoadsConditionAndScenariosShared.getStepVisibleAnomalies(step, scoringParameters, props.activeAnomalies);

                    if (anomalies.size >= 1) {
                        let anomalyPoint = RoadsConditionAndScenariosShared.createAnomaliesShape(step, anomalies);
                        anomaliesDatasource.add(anomalyPoint);
                    }
                });

                //si l'icone voiture est affichée, on la supprime et on la recrée pour qu'elle soit toujours positionnée au dessus
                //TODO HGA
            }

            setActiveAnomalies(props.activeAnomalies);
        }
    }, [props.activeAnomalies]);

    useEffect(() => {
        let datasource = azureMap.current.sources.getById(mainDatasourceId) as source.DataSource;
        if (datasource) {
            datasource.getShapes().forEach((section: Shape) => {
                let properties = section.getProperties();
                let entityType = properties.EntityType;
                if (entityType === ShapeEntityType.section) {
                    let sectionId = properties.RoadSectionId;
                    let sectionView = props.mergedProject.roadsSections.get(sectionId);

                    let strokeColor = styles.unfilteredSectionColor;
                    let sectionScoreType = sectionView.scoreType;

                    if (props.filterdSectionsIds.has(sectionId) || props.selectedSectionsIds.has(sectionId)) {
                        strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;
                    }

                    if (strokeColor !== properties.strokeColor) {
                        properties.strokeColor = strokeColor;
                        section.setProperties(properties);
                    }
                }
            });
        }
    }, [props.filterdSectionsIds]);

    useEffect(() => {
        removeFilteredAreasDatasourceShapes();

        if (props.filteredProgrammingsFromFilter) {
            let datasource = createFilteredAreasDatasource();

            props.filteredProgrammingsFromFilter.forEach((p) => {
                createFilteredMapAreasShapes(p, datasource);
            });
        }
    }, [props.filteredProgrammingsFromFilter])

    useEffect(() => {
        azureMap.current.resize("100%", "100%");
    }, [props.isSectionsDrawerOpened])

    useEffect(() => {
        return function cleanup() {
            azureMap.current?.dispose();
        }
    }, [])

    const initMap = (map: AzureMap, callback: () => void): void => {
        setMapCursor(map, MapCursorMode.Auto);

        map.events.add('load', () => {
            if (callback) {
                callback();
            }
        });
    }

    const createMapSectionsShapes = (mergedProject: MergedProjectVersion, selectedSectionsIds: Set<number>): void => {
        let datasource = createMainDatasource();

        mergedProject.roadsSections.forEach((section) => {
            let coordinates = section.pathGeometry.coordinates;
            let roadSectionId = section.roadSectionId;

            let selectedSectionShapeWidth = 0;
            let selectedSectionColor = transparentColor;
            if (selectedSectionsIds.has(roadSectionId)) {
                selectedSectionShapeWidth = selectedSectionWidth;
                selectedSectionColor = styles2.selectedSectionColor;
            }

            let selectedSectionShapeId = getSelectedSectionShapeId(roadSectionId);
            let selectedSectionShape = createShape(coordinates, selectedSectionShapeId, selectedSectionColor, selectedSectionShapeWidth, ShapeEntityType.sectionSelected, roadSectionId);
            datasource.add(selectedSectionShape);

            let sectionShapeId = getSectionShapeId(roadSectionId);
            let sectionScoreType = section.scoreType;
            let strokeColor = sectionScoreType ? ScoreTypesColors.get(sectionScoreType) : styles2.emptyQualityColor;

            let sectionShape = createShape(coordinates, sectionShapeId, strokeColor, sectionWidth, ShapeEntityType.section, roadSectionId, sectionScoreType);
            datasource.add(sectionShape);
        });

        setSelectedSectionsId(Array.from(selectedSectionsIds.keys()));
    }

    const createMainDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(mainDatasourceId);
        azureMap.current.sources.add(datasource);

        let roadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, roadLayerId);
        azureMap.current.layers.add(roadLayer);

        return datasource;
    }

    const createFilteredAreasDatasource = (): source.DataSource => {
        let datasource = new source.DataSource(filteredAreasDatasource);
        azureMap.current.sources.add(datasource);

        let filteredAreasRoadLayer = RoadsConditionAndScenariosShared.createLineLayer(datasource, filteredAreasRoadLayerId);
        azureMap.current.layers.add(filteredAreasRoadLayer);

        azureMap.current.events.add('mouseover', filteredAreasRoadLayer, (e) => handleRoadLayerMouseover(e));
        azureMap.current.events.add('mouseout', filteredAreasRoadLayer, handleRoadLayerMouseout);

        return datasource;
    }

    const removeFilteredAreasDatasourceShapes = (): void => {
        let filteredAreasRoadLayer = azureMap.current.layers.getLayerById(filteredAreasRoadLayerId);
        if (filteredAreasRoadLayer) {
            azureMap.current.events.remove('mouseover', filteredAreasRoadLayer, (e) => handleRoadLayerMouseover(e as MapMouseEvent));
            azureMap.current.events.remove('mouseout', filteredAreasRoadLayer, handleRoadLayerMouseout);
            azureMap.current.layers.remove(filteredAreasRoadLayer);
        }

        let datasource = azureMap.current.sources.getById(filteredAreasDatasource) as source.DataSource;
        if (datasource) {
            datasource.clear();
            azureMap.current.sources.remove(datasource);
        }
    }

    const createFilteredMapAreasShapes = (programming: FilteredProgramming, datasource: source.DataSource): void => {
        if (programming) {
            programming.areas.forEach((area) => {
                area.sections.forEach((section) => {
                    let sectionId = section.roadSectionId;
                    let filteredAreaShapeId = getFilteredAreaShapeId(sectionId);
                    let unselectedAreaShape = createShape(section.pathGeometry.coordinates, filteredAreaShapeId, area.hexColor, areaWidth, filteredArea, sectionId);
                    datasource.add(unselectedAreaShape);
                });
            });
        }
    }

    const getFilteredAreaShapeId = (roadSectionId: number): string =>
        `${roadSectionId}/filteredArea`;

    const handleRoadLayerMouseover = (e: MapMouseEvent): void => {
        handleLayerMouseover();

        let tooltips: string[] = [];
        let areasAlreadyChecked = new Map<number, number>();
        e.shapes.forEach((s) => {
            let shape = s as Shape;
            let shapeProps = shape.getProperties();
            if (shapeProps.EntityType === filteredArea) {
                let sectionId = shapeProps.RoadSectionId;

                props.filteredProgrammingsFromFilter.forEach(p => {
                    let areas = p.areas;
                    areas.forEach((area) => {
                        if (!areasAlreadyChecked.has(area.programmingAreaId) && area.sections.some(s => s.roadSectionId === sectionId)) {
                            areasAlreadyChecked.set(area.programmingAreaId, area.programmingAreaId);
                            let tooltip = getAreaTooltip(p.year, p.label, area.label, area.selectedWork);
                            if (tooltip) {
                                tooltips.push(tooltip);
                            }
                        }
                    });
                });
            }
        });

        if (tooltips.length > 0) {
            let content = `<div style="padding:10px;">`;
            tooltips.forEach(tooltip => {
                content += `<p style="margin:0;">${tooltip}</p>`;
            });
            content += `</div>`;

            areaTooltipPopup.setOptions({
                content: content,
                position: e.position,
                pixelOffset: [0, -18]
            });
            areaTooltipPopup.open(azureMap.current);
        }
    }

    const handleRoadLayerMouseout = (): void => {
        handleLayerMouseout();

        if (areaTooltipPopup.isOpen()) {
            areaTooltipPopup.close();
        }
    }

    const setMapZoom = (map: AzureMap, mergedProject: MergedProjectVersion): void => {
        let options: CameraBoundsOptions & AnimationOptions = {
            bounds: data.BoundingBox.fromBoundingBox(new data.BoundingBox(mergedProject.southWesternBoundingLocationGeometry.coordinates, mergedProject.northEasternBoundingLocationGeometry.coordinates)),
            padding: 20
        };

        options.type = cameraAnimationType;
        options.duration = cameraAnimationDuration;

        map.setCamera(options);
    }

    const anomalyLayerMouseover = (): void => {
        handleLayerMouseover();
    }

    const anomalyLayerMouseout = (): void => {
        handleLayerMouseout();
    }

    const handleLayerMouseover = (): void => {
        setMapCursor(azureMap.current, MapCursorMode.Pointer);
    }

    const handleLayerMouseout = (): void => {
        setMapCursor(azureMap.current, MapCursorMode.Auto);
    }

    const handleAnomalyPointClicked = (e: void | MapMouseEvent | layer.Layer, mergedProject: MergedProjectVersion): void => {
        e = e as MapMouseEvent;
        let shape = e.shapes[0] as Shape;
        let shapeProps = shape.getProperties();
        let section: RoadSectionViewData = mergedProject.roadsSections.get(shapeProps.sectionId);
        if (section) {
            //let clickedPosition = e.position;
            // TODO HGA handleSectionClicked(section, clickedPosition);
        }
    }

    return (
        <Box className={`maintenance-scenario-map-content ${props.isSectionsDrawerOpened ? 'sections-opened' : ''}`}>
            {props.loading ? <ScaleLoaderComponent /> : ''}
            <div id="measurementInfo" className="measure"></div>
            <div className="map-actions">
                {props.mergedProject &&
                    <MapActionBar
                        azureMap={azureMap.current}
                        actions={[barAction.MonoSelect, barAction.ZoneSelect, barAction.ClearZone, barAction.Measure]}
                        onSelectedSectionChange={props.onSelectedSectionChange}
                        selectedSectionsId={Array.from(props.selectedSectionsIds)}
                        sections={props.mergedProject.roadsSections}
                        mainLayer={roadLayerId}
                        currentMeasurementSystemType={props.currentMeasurementSystemType}
                        selectedDefaultAction={barAction.MonoSelect}
                    />
                }
            </div>
            <div id="AzureMap"></div>
        </Box>
    );
}
