import { data, layer, Map as AzureMap, MapMouseEvent, Shape, source } from 'azure-maps-control';
import { enlightenedAreaWidth, hideShape, setMapCameraFromPositions, transparentColor } from '../../shared/Map/MapUtils';
import { Point } from '../../shared/models/Point';
import { ScoreTypes } from '../../shared/models/ScoreTypes';
import { ShapeEntityType } from '../../shared/models/ShapeEntityType';
import '../../utils/Map';
import MathExtensions from '../../utils/MathExtensions';
import '../../utils/String';
import Utilities from '../../utils/Utilities';
import styles from "../../_variables.scss";
import styles2 from '../../_variables2.scss';
import { ProjectThumbnailModel } from '../Home/models/ProjectThumbnailModel';
import { Auscultation } from '../Home/services/dataContracts/queryStack/Auscultation';
import { ProjectVersion } from '../Home/services/dataContracts/queryStack/ProjectVersion';
import { ScoringParameter } from '../Home/services/dataContracts/queryStack/ScoringParameter';
import { HomeApiClient } from '../Home/services/HomeApiClient';
import { AuscultationExtended } from './models/AuscultationExtended';
import { ImageExtended } from './models/ImageExtended';
import { MergedProjectVersion } from './models/MergedProjectVersion';
import { ProjectVersionExtended } from './models/ProjectVersionExtended';
import { QualityModel } from './models/QualityModel';
import { QualityType } from './models/QualityType';
import { RoadSectionExtended } from './models/RoadSectionExtended';
import { RoadSectionScoreExtended } from './models/RoadSectionScoreExtended';
import { RoadSectionViewData } from './models/RoadSectionViewData';
import { RoadStepViewData } from './models/RoadStepViewData';
import { RoadTrunkExtended } from './models/RoadTrunkExtended';
import { RoadTrunkViewData } from './models/RoadTrunkViewData';
import { ScoreColors } from './models/ScoreColors';
import { ScoreTypesColors } from './models/ScoreTypesColors';
import { roadTypesWidthInMeters } from './RoadTypesWidths';
import { Image } from './services/RoadsCondition/dataContracts/queryStack/Image';
import { MultiAuscultationMergedScores } from './services/RoadsCondition/dataContracts/queryStack/MultiAuscultationMergedScores';
import { RoadSectionAttributes } from './services/RoadsCondition/dataContracts/queryStack/RoadSectionAttributes';
import { RoadSectionScore } from './services/RoadsCondition/dataContracts/queryStack/RoadSectionScore';
import { RoadTrunk } from './services/RoadsCondition/dataContracts/queryStack/RoadTrunk';
import { RoadTrunkLabelsScoreRanking } from './services/RoadsCondition/dataContracts/queryStack/RoadTrunkLabelsScoreRanking';
import { RoadsConditionApiClient } from './services/RoadsCondition/RoadsConditionApiClient';

export const mainDatasourceId = "main";
export const anomaliesDatasourceId = "anomalies";

export const roadLayerId = "roadLayerId";
export const anomaliesLayerId = "anomaliesLayerId";
export const clusterBubbleLayerId = "clusterBubbleLayerId";
export const clusterCountLayerId = "clusterCountLayerId";

export const anomaliesColors = new Map<string, string>([
    ['affaissement_de_rive', styles2.affaissementDeRiveAnomalyColor],
    ['affaissement_de_rive_G1', styles2.affaissementDeRiveG1AnomalyColor],
    ['affaissement_de_rive_G2', styles2.affaissementDeRiveG2AnomalyColor],
    ['bordure_trottoir', styles2.bordureTrottoirAnomalyColor],
    ['arrachement', styles2.arrachementAnomalyColor],
    ['desenrobage_plumage', styles2.desenrobagePlumageAnomalyColor],
    ['emergence_sur_chaussee', styles2.emergenceSurChausseeAnomalyColor],
    ['faiençage', styles2.faiençageAnomalyColor],
    ['fissuration_multidirectionnelle', styles2.fissurationMultidirectionnelleAnomalyColor],
    ['faiençage_ponte', styles2.faiençagePonteAnomalyColor],
    ['fissure_longitudinale', styles2.fissureLongitudinaleAnomalyColor],
    ['fiss_long_ponte', styles2.fissLongPonteAnomalyColor],
    ['fissure_transversale', styles2.fissureTransversaleAnomalyColor],
    ['fiss_trans_ponte', styles2.fissTransPonteAnomalyColor],
    ['joint_longitudinal', styles2.jointLongitudinalAnomalyColor],
    ['joint_longitudinal_ponte', styles2.jointLongitudinalPonteAnomalyColor],
    ['nid_de_poule', styles2.nidDePouleAnomalyColor],
    ['nid_de_poule_repare', styles2.nidDePouleRepareAnomalyColor],
    ['peignage', styles2.peignageAnomalyColor],
    ['pelade', styles2.peladeAnomalyColor],
    ['reparation', styles2.reparationAnomalyColor],
    ['reparation_enrobe', styles2.reparationEnrobeAnomalyColor],
    ['reparation_pata', styles2.reparationPataAnomalyColor],
    ['ressuage', styles2.ressuageAnomalyColor],
    ['tranchee', styles2.trancheeAnomalyColor],
    ['tranchee_transversale', styles2.trancheeTransversaleAnomalyColor],
    ['joint_long_ponte', styles2.jointLongPonteAnomalyColor],
    ['feu_de_circulation', styles2.feuDeCirculationAnomalyColor],
    ['passage_pieton', styles2.passagePietonAnomalyColor],
    ['pontage', styles2.pontageAnomalyColor],
    ['tampon', styles2.tamponAnomalyColor]
]);

export class RoadsConditionAndScenariosShared {

    public static getMergedProject = async (projectVersionId: number, mergedProjectAuscultationsCache: Map<number, MergedProjectVersion>, projectVersionsCache: Map<number, ProjectVersion>): Promise<MergedProjectVersion> => {
        let result = mergedProjectAuscultationsCache.get(projectVersionId);
        if (result) {
            return Promise.resolve(result);
        }

        let project = await RoadsConditionAndScenariosShared.getVersionOfProject(projectVersionId, projectVersionsCache);
        let projectAuscultationsArray = project.auscultations;

        let auscultationsIdsArray: number[] = projectAuscultationsArray.map(x => x.auscultationId);
        let auscultationsIdsString: string = Utilities.GetCommaSeparatedString(auscultationsIdsArray);
        let multiAuscultationMergedScores = await RoadsConditionAndScenariosShared.getMergedScoresRawData(project.projectVersionId, auscultationsIdsString);

        let projectVersionData = await RoadsConditionAndScenariosShared.getProjectVersionRawData(project.projectVersionId);
        let projectVersion: ProjectVersionExtended = RoadsConditionAndScenariosShared.buildExtendedProjectVersion(project, projectVersionData.roadsTrunks, projectVersionData.imagesData, projectVersionData.roadSectionsScoresData);

        let roadSectionsIds = Array.from(projectVersion.roadsSections.keys());
        let roadSectionsAttributesData = await RoadsConditionAndScenariosShared.getAttributesByRoadSections(project.projectId, roadSectionsIds);
        let roadSectionsAttributes = RoadsConditionAndScenariosShared.buildRoadSectionsAttributes(roadSectionsAttributesData);

        let roadTrunkLabelsScoreRanking = await RoadsConditionAndScenariosShared.getRoadTrunkLabelsScoreRanking(roadSectionsIds, project.projectVersionId, auscultationsIdsString);

        let mergedProject: MergedProjectVersion = RoadsConditionAndScenariosShared.buildMergedProject(projectVersion, roadSectionsAttributes, multiAuscultationMergedScores, auscultationsIdsString, roadTrunkLabelsScoreRanking);

        result = mergedProject;

        mergedProjectAuscultationsCache.set(projectVersionId, result);

        return result;
    }

    public static getVersionOfProject = (projectVersionId: number, projectVersionsCache: Map<number, ProjectVersion>): Promise<ProjectVersion> => {
        let projectVersion = projectVersionsCache.get(projectVersionId);
        if (projectVersion) {
            return Promise.resolve(projectVersion);
        }

        return HomeApiClient.GetVersionOfProject(projectVersionId)
            .then((res) => {
                if (res) {
                    projectVersion = res.data[0]
                    projectVersionsCache.set(projectVersionId, projectVersion);
                    return projectVersion;
                }

                return null;
            });
    }

    public static getMergedScoresRawData = (projectVersionId: number, auscultationsIdsString: string): Promise<MultiAuscultationMergedScores> => {
        return RoadsConditionApiClient.GetMergedScores(projectVersionId, auscultationsIdsString)
            .then((res) => {
                if (res) {
                    return res.data;
                }

                return null;
            });
    }

    public static getAttributesByRoadSections = (projectId: string, RoadSectionsIds: number[]): Promise<RoadSectionAttributes[]> => {
        return RoadsConditionApiClient.GetAttributesByRoadSections(projectId, RoadSectionsIds)
            .then((res) => {
                if (res) {
                    return res.data;
                }

                return null;
            });
    }

    public static getRoadTrunkLabelsScoreRanking = (RoadSectionsIds: number[], projectVersionId: number, auscultationsIds: string): Promise<RoadTrunkLabelsScoreRanking> => {
        return RoadsConditionApiClient.GetRoadTrunkLabelsScoreRanking(RoadSectionsIds, projectVersionId, auscultationsIds)
            .then((res) => {
                if (res) {
                    return res.data;
                }

                return null;
            });
    }

    public static getProjectVersionRawData = async (projectVersionId: number): Promise<{ roadsTrunks: RoadTrunk[], imagesData: Map<number, Image[]>, roadSectionsScoresData: Map<number, RoadSectionScore[]> }> => {
        return await Promise.all([
            RoadsConditionApiClient.GetRoadsTrunks(projectVersionId),
            RoadsConditionApiClient.GetPerAuscultationImages(projectVersionId),
            RoadsConditionApiClient.GetPerAuscultationScores(projectVersionId)
        ])
            .then((res) => {
                if (res) {
                    let roadsTrunks = res[0].data;
                    let imagesData = res[1].data;
                    let roadSectionsScoresData = res[2].data;

                    return {
                        roadsTrunks: roadsTrunks,
                        imagesData: imagesData,
                        roadSectionsScoresData: roadSectionsScoresData
                    }
                }

                return null;
            });
    }

    public static buildExtendedProjectVersion = (projectVersion: ProjectThumbnailModel, roadsTrunks: RoadTrunk[], imagesData: Map<number, Image[]>, roadSectionsScoresData: Map<number, RoadSectionScore[]>): ProjectVersionExtended => {
        let imagesMap = new Map<number, Image[]>();
        imagesMap = imagesMap.fromObject(imagesData, true);

        let perAuscultationScoresMap = new Map<number, RoadSectionScore[]>();
        perAuscultationScoresMap = perAuscultationScoresMap.fromObject(roadSectionsScoresData, true);

        let roadSectionsScoresMap = new Map<number, RoadSectionScoreExtended>();
        perAuscultationScoresMap.forEach((roadSectionScores: RoadSectionScore[], auscultationId: number) => {
            roadSectionScores.forEach((roadSectionScore: RoadSectionScore) => {
                let roadSectionScoreExtended = roadSectionScore as RoadSectionScoreExtended;
                roadSectionScoreExtended.auscultationId = auscultationId;
                roadSectionsScoresMap.set(roadSectionScore.roadSectionScoreId, roadSectionScoreExtended);
            });
        });

        let roadsTrunksArray = roadsTrunks as RoadTrunkExtended[];
        let scoringParametersDico = new Map<string, ScoringParameter>();
        projectVersion.scoringParameters.forEach((scoringParameter: ScoringParameter) => {
            scoringParametersDico.set(scoringParameter.anomalyType, scoringParameter);
        });

        let extendedRoadsTrunks: Map<number, RoadTrunkExtended> = new Map<number, RoadTrunkExtended>();
        let roadsSections = new Map<number, RoadSectionExtended>();

        roadsTrunksArray.forEach((roadTrunk: RoadTrunkExtended) => {
            roadTrunk.extendedSections = new Map<number, RoadSectionExtended>();
            roadTrunk.widthInMeters = roadTypesWidthInMeters.get(roadTrunk.roadType) ?? roadTypesWidthInMeters.get('autres');

            let sections = roadTrunk.sections as RoadSectionExtended[];
            sections.forEach((section: RoadSectionExtended) => {
                let extendedSection: RoadSectionExtended = section;
                extendedSection.roadLabel = roadTrunk.label ?? roadTrunk.referenceRoadTrunkId;
                extendedSection.roadTrunkExtended = roadTrunk;

                roadTrunk.extendedSections.set(extendedSection.roadSectionId, extendedSection);
                roadsSections.set(extendedSection.roadSectionId, extendedSection);
            });

            extendedRoadsTrunks.set(roadTrunk.roadTrunkId, roadTrunk);
        });

        let roadSectionsScoresExtendedMap: Map<number, RoadSectionScoreExtended> = new Map<number, RoadSectionScoreExtended>();
        let auscultations = new Map<number, AuscultationExtended>();
        projectVersion.auscultations.forEach((auscultation) => {
            let imagesData = imagesMap.get(auscultation.auscultationId) as ImageExtended[];
            let images = RoadsConditionAndScenariosShared.buildExtendedImagesDictionary(imagesData, auscultation);
            let roadSectionsScores = perAuscultationScoresMap.get(auscultation.auscultationId);
            let bySectionIdMap = new Map<number, RoadSectionScoreExtended>();
            roadSectionsScores.forEach((element) => {
                let roadSectionsScore = element as RoadSectionScoreExtended;
                let score = roadSectionsScore?.score;
                let scoreColor: string = ScoreColors.black;
                if (roadSectionsScore?.roadSectionScoreId !== null) {
                    scoreColor = RoadsConditionAndScenariosShared.getScoreColor(score);
                }

                roadSectionsScore.color = scoreColor;
                bySectionIdMap.set(roadSectionsScore.roadSectionId, roadSectionsScore);

                let roadSectionScoreExtended = roadSectionsScoresMap.get(roadSectionsScore.roadSectionScoreId) as RoadSectionScoreExtended;
                roadSectionScoreExtended.color = scoreColor;
                roadSectionsScoresExtendedMap.set(roadSectionsScore.roadSectionScoreId, roadSectionScoreExtended);
            });

            let perSectionImages: Map<number, ImageExtended[]> = new Map<number, ImageExtended[]>();
            imagesData.forEach((image: ImageExtended) => {
                let array: ImageExtended[] = perSectionImages.get(image.roadSectionId);
                if (array != null) {
                    array.push(image);
                }
                else {
                    array = [image];
                }
                perSectionImages.set(image.roadSectionId, array);
            });

            let perStepImages: Map<number, ImageExtended[]> = new Map<number, ImageExtended[]>();
            imagesData.forEach((image: ImageExtended) => {
                let array: ImageExtended[] = perStepImages.get(image.roadStepId);
                if (array != null) {
                    array.push(image);
                }
                else {
                    array = [image];
                }
                perStepImages.set(image.roadStepId, array);
            });


            let auscultationExtended = auscultation as AuscultationExtended;

            auscultationExtended.imagesDico = images;
            auscultationExtended.roadSectionsScores = bySectionIdMap;
            auscultationExtended.perSectionImages = perSectionImages;
            auscultationExtended.perStepImages = perStepImages;
            auscultations.set(auscultationExtended.auscultationId, auscultationExtended);
        });

        let result: ProjectVersionExtended = {
            auscultations: projectVersion.auscultations,
            projectId: projectVersion.projectId,
            projectVersionId: projectVersion.projectVersionId,
            versionNumber: projectVersion.versionNumber,
            isCurrentVersion: projectVersion.isCurrentVersion,
            label: projectVersion.label,
            imageBlobName: projectVersion.imageBlobName,
            locationGeometry: projectVersion.locationGeometry,
            scoringParameters: projectVersion.scoringParameters,
            images: imagesMap,
            roadsTrunks: roadsTrunks,
            perAuscultationScores: perAuscultationScoresMap,
            roadSectionsScores: roadSectionsScoresExtendedMap,
            scoringParametersDico: scoringParametersDico,
            extendedAuscultations: auscultations,
            roadsSections: roadsSections,
            extendedRoadsTrunks: extendedRoadsTrunks
        };

        return result;
    }

    public static buildExtendedImagesDictionary = (images: ImageExtended[], auscultation: Auscultation): Map<number, ImageExtended> => {
        let imagesDico: Map<number, ImageExtended> = new Map<number, ImageExtended>();
        images.forEach((image: ImageExtended) => {
            if (image.score !== null) {
                image.scoreColor = RoadsConditionAndScenariosShared.getScoreColor(image.score);
                image.displayedId = image.referenceImageId.replace('image', '');
            }
            image.auscultation = auscultation;

            imagesDico.set(image.imageId, image);
        });

        return imagesDico;
    }

    public static buildRoadSectionsAttributes = (roadSectionsAttributesData: RoadSectionAttributes[]): Map<number, RoadSectionAttributes> => {
        let roadSectionsAttributes = new Map<number, RoadSectionAttributes>();
        roadSectionsAttributesData.forEach((roadSectionAttribute: RoadSectionAttributes) => {
            roadSectionsAttributes.set(roadSectionAttribute.roadSectionId, roadSectionAttribute);
        });

        return roadSectionsAttributes;
    }

    public static buildMergedProject = (project: ProjectVersionExtended, roadSectionsAttributes: Map<number, RoadSectionAttributes>, multiAuscultationMergedScores: MultiAuscultationMergedScores, auscultationsIdsString: string, roadTrunkLabelsScoreRanking?: RoadTrunkLabelsScoreRanking): MergedProjectVersion => {
        let boundingBox = RoadsConditionAndScenariosShared.computeBoundingBox(project.auscultations);
        let southWesternBoundingLocationGeometry = boundingBox.southWesternBoundingLocationGeometry;
        let northEasternBoundingLocationGeometry = boundingBox.northEasternBoundingLocationGeometry;
        let extendedRoadsTrunks = new Map<number, RoadTrunkViewData>();
        let extendedSections = new Map<number, RoadSectionViewData>();
        let extendedSteps = new Map<number, RoadStepViewData>();

        let municipalities = new Set<string>();
        let districts = new Set<string>();
        let collaborativeDevelopmentZones = new Set<string>();

        let mergedRoadSectionScoresIds = multiAuscultationMergedScores.roadSectionScoresIds;
        mergedRoadSectionScoresIds.forEach((roadSectionScoresId) => {
            let sectionScore = project.roadSectionsScores.get(roadSectionScoresId);
            let auscultationId = sectionScore.auscultationId;
            let auscultation = project.extendedAuscultations.get(auscultationId);
            let roadSection = project.roadsSections.get(sectionScore.roadSectionId);

            let roadSectionAttributes = roadSectionsAttributes.get(roadSection.roadSectionId);
            let municipality = roadSectionAttributes.municipality ?? null;
            let district = roadSectionAttributes.district ?? null;
            let collaborativeDevelopmentZone = roadSectionAttributes.collaborativeDevelopmentZone ?? null;

            let sectionViewData: RoadSectionViewData = {
                roadSectionId: roadSection.roadSectionId,
                roadSectionAttributeId: roadSectionAttributes.roadSectionAttributeId,
                labelLowerWithoutDiacritics: roadSectionAttributes.roadLabel ? roadSectionAttributes.roadLabel.toLowerCase().removeDiacritics() : null,
                lengthInMeters: roadSection.lengthInMeters,
                widthInMeters: roadSectionAttributes.widthInMeters,
                roadType: roadSection.roadTrunkExtended.roadType,
                municipality: municipality,
                district: district,
                collaborativeDevelopmentZone: collaborativeDevelopmentZone,
                hierarchy: roadSectionAttributes.hierarchy ?? null,
                traffic: roadSectionAttributes.traffic ?? null,
                environment: roadSectionAttributes.environment ?? null,
                manager: roadSectionAttributes.manager ?? null,
                importance: roadSectionAttributes.importance ?? null,
                bus: roadSectionAttributes.bus ?? null,
                bikeLase: roadSectionAttributes.bikeLase ?? null,
                border: roadSectionAttributes.border ?? null,
                ditch: roadSectionAttributes.ditch ?? null,
                side: roadSectionAttributes.side ?? null,
                pathGeometry: roadSection.pathGeometry,
                roadLabel: roadSectionAttributes.roadLabel,
                images: auscultation.perSectionImages.get(roadSection.roadSectionId),
                scoreColor: sectionScore.color,
                scoreType: sectionScore.color === ScoreColors.monitoring ? ScoreTypes.monitoring :
                    (sectionScore.color === ScoreColors.localizedRepair ? ScoreTypes.localizedRepair :
                        (sectionScore.color === ScoreColors.generalMaintenance ? ScoreTypes.generalMaintenance :
                            (sectionScore.color === ScoreColors.reinforcement ? ScoreTypes.reinforcement :
                                (sectionScore.color === ScoreColors.rehabilitation ? ScoreTypes.rehabilitation : null)))),
                score: sectionScore.score,
                scoreDetail: JSON.parse(sectionScore.scoreDetail),
                roadSectionScoreId: sectionScore.roadSectionScoreId,
                anomaliesCounters: null,
                roadTrunkExtended: null,
                sectionLabels: roadSection.roadSectionLabels.filter(l => l.auscultationId === auscultationId),
                videoDateTime: auscultation.videoDateTime?.toLocaleDateString(),
                osmRoadLabel: roadSection.osmRoadLabel,
                osmMunicipality: roadSection.osmMunicipality
            };

            extendedSections.set(sectionViewData.roadSectionId, sectionViewData);

            let road = roadSection.roadTrunkExtended;
            let roadViewData = extendedRoadsTrunks.get(road.roadTrunkId);
            if (!roadViewData) {
                roadViewData = {
                    roadTrunkId: road.roadTrunkId,
                    extendedSections: new Map<number, RoadSectionViewData>(),
                    scoringParameters: project.scoringParametersDico,
                    widthInMeters: road.widthInMeters
                };

                extendedRoadsTrunks.set(roadViewData.roadTrunkId, roadViewData);
            }

            sectionViewData.roadTrunkExtended = roadViewData;
            roadViewData.extendedSections.set(sectionViewData.roadSectionId, sectionViewData);

            roadSection.roadSteps.forEach((step) => {
                extendedSteps.set(step.roadStepId, {
                    roadStepId: step.roadStepId,
                    roadSectionId: step.roadSectionId,
                    pathGeometry: step.pathGeometry,
                    scoringParameters: project.scoringParametersDico,
                    images: auscultation.perStepImages.get(step.roadStepId)
                });
            });

            if (municipality && !municipalities.has(municipality)) {
                municipalities.add(municipality);
            }

            if (district && !districts.has(district)) {
                districts.add(district);
            }

            if (collaborativeDevelopmentZone && !collaborativeDevelopmentZones.has(collaborativeDevelopmentZone)) {
                collaborativeDevelopmentZones.add(collaborativeDevelopmentZone);
            }
        });

        let imagesDico = new Map<number, ImageExtended>();
        let trustedAnomaliesEnteringScore = new Set<string>();
        let trustedAnomaliesOther = new Set<string>();
        extendedSections.forEach((s) => {
            if (s.images) {
                s.images.forEach((i) => {
                    imagesDico.set(i.imageId, i);
                    if (i.imageAnomalies && i.imageAnomalies.length > 0) {
                        i.imageAnomalies.forEach((anomaly) => {
                            let scoringParameter = project.scoringParametersDico.get(anomaly.labelType);
                            if (scoringParameter && anomaly.confidence >= scoringParameter.confidenceThreshold) {
                                if (scoringParameter.weight > 0 && !trustedAnomaliesEnteringScore.has(anomaly.labelType)) {
                                    trustedAnomaliesEnteringScore.add(anomaly.labelType);
                                }
                                else if ((scoringParameter.weight == null || scoringParameter.weight === 0) && !trustedAnomaliesOther.has(anomaly.labelType)) {
                                    trustedAnomaliesOther.add(anomaly.labelType);
                                }
                            }
                        });
                    }
                });
            }
        });

        let result: MergedProjectVersion = {
            ...multiAuscultationMergedScores,
            projectLabel: project.label,
            projectLocationGeometry: project.locationGeometry,
            projectVersion: project,
            extendedRoadsTrunks: extendedRoadsTrunks,
            imagesDico: imagesDico,
            roadsSections: extendedSections,
            roadsSteps: extendedSteps,
            southWesternBoundingLocationGeometry: southWesternBoundingLocationGeometry,
            northEasternBoundingLocationGeometry: northEasternBoundingLocationGeometry,
            auscultationsIdsString: auscultationsIdsString,
            trustedAnomaliesEnteringScore: trustedAnomaliesEnteringScore,
            trustedAnomaliesOther: trustedAnomaliesOther,
            municipalities: municipalities,
            districts: districts,
            collaborativeDevelopmentZones: collaborativeDevelopmentZones,
            roadTrunkLabelsScoreRanking: roadTrunkLabelsScoreRanking
        };

        return result;
    }

    public static computeBoundingBox = (auscultations: Auscultation[]): { southWesternBoundingLocationGeometry: Point, northEasternBoundingLocationGeometry: Point } => {
        let minLongitude = null;
        let minLatitude = null;
        let maxLongitude = null;
        let maxLatitude = null;

        auscultations.forEach((item) => {
            let minLong = item.southWesternBoundingLocationGeometry.coordinates[0];
            let minLat = item.southWesternBoundingLocationGeometry.coordinates[1];
            minLongitude = minLongitude ? Math.min(minLongitude, minLong) : minLong;
            minLatitude = minLatitude ? Math.min(minLatitude, minLat) : minLat;

            let maxLong = item.northEasternBoundingLocationGeometry.coordinates[0];
            let maxLat = item.northEasternBoundingLocationGeometry.coordinates[1];
            maxLongitude = maxLongitude ? Math.max(maxLongitude, maxLong) : maxLong;
            maxLatitude = maxLatitude ? Math.max(maxLatitude, maxLat) : maxLat;
        });

        let southWesternBoundingLocationGeometryCoordinates = new data.Position(minLongitude, minLatitude);
        let southWesternBoundingLocationGeometry: Point = {
            coordinates: southWesternBoundingLocationGeometryCoordinates,
            type: "Point"
        };

        let northEasternBoundingLocationGeometryCoordinates = new data.Position(maxLongitude, maxLatitude);
        let northEasternBoundingLocationGeometry: Point = {
            coordinates: northEasternBoundingLocationGeometryCoordinates,
            type: "Point"
        };

        return {
            southWesternBoundingLocationGeometry: southWesternBoundingLocationGeometry,
            northEasternBoundingLocationGeometry: northEasternBoundingLocationGeometry
        };
    }

    public static getScoreColor = (score: number): string => {
        if (score === null)
            return ScoreColors.undetermined;
        else if (score === 10 || score === 9 || score === 8)
            return ScoreColors.monitoring;
        else if (score === 7 || score === 6)
            return ScoreColors.localizedRepair;
        else if (score === 5 || score === 4)
            return ScoreColors.generalMaintenance;
        else if (score === 3 || score === 2)
            return ScoreColors.reinforcement;
        else if (score === 1)
            return ScoreColors.rehabilitation;
        else
            return null;
    }

    public static createAnomaliesShape = (step: RoadStepViewData, anomalies: Set<string>): Shape => {
        let color = styles2.mixedAnomaliesColor;
        if (anomalies.size === 1) {
            const anomaly = anomalies.keys().next().value;
            color = anomaliesColors.get(anomaly);
        }
        let stepId = step.roadStepId;
        let anomalyShapeId = RoadsConditionAndScenariosShared.getAnomalyShapeId(stepId);
        let coordinates = step.pathGeometry.coordinates;
        let midPoint = MathExtensions.interpolateMidPoint(coordinates);

        return new Shape(new data.Point(midPoint), anomalyShapeId, { EntityType: ShapeEntityType.anomaly, color: color, anomalyBubble: true, radius: 6, anomalies: anomalies, RoadSectionId: step.roadSectionId });
    }

    public static getAnomalyShapeId = (roadStepId: number): string => {
        return `${roadStepId}/anomaly`;
    }

    public static createLineLayer = (datasource: source.DataSource, layerId: string): layer.LineLayer => {
        return new layer.LineLayer(datasource, layerId, {
            strokeColor: ['case', ['has', 'strokeColor'], ['get', 'strokeColor'], transparentColor],
            strokeWidth: ['case', ['has', 'strokeWidth'], ['get', 'strokeWidth'], 0],
            lineJoin: 'round', lineCap: 'round', visible: true
        });
    }

    public static createAnomaliesLayer = (datasource: source.DataSource, layerId: string): layer.BubbleLayer => {
        return new layer.BubbleLayer(datasource, layerId, {
            color: ['get', 'color'],
            radius: ['get', 'radius'],
            filter: ['!', ['has', 'point_count']]
        });
    }

    public static recreateAnomaliesDatasource = (map: AzureMap, mergedProject: MergedProjectVersion, hasAnomaliesLayerMapEvent: boolean, anomalyLayerMouseover?: () => void, anomalyLayerMouseout?: () => void, anomalyPointClickHandler?: (e: void | MapMouseEvent | layer.Layer) => void, handleAnomalyPointClicked?: (e: void | MapMouseEvent | layer.Layer, mergedProject: MergedProjectVersion) => void): source.DataSource => {
        let anomaliesDatasource = map.sources.getById(anomaliesDatasourceId) as source.DataSource;
        if (anomaliesDatasource) {
            RoadsConditionAndScenariosShared.removeAnomaliesDatasource(map, hasAnomaliesLayerMapEvent, anomalyLayerMouseover, anomalyLayerMouseout, anomalyPointClickHandler);
        }

        anomaliesDatasource = RoadsConditionAndScenariosShared.createAnomaliesDatasource(map, mergedProject, hasAnomaliesLayerMapEvent, anomalyLayerMouseover, anomalyLayerMouseout, anomalyPointClickHandler, handleAnomalyPointClicked);
        return anomaliesDatasource;
    }

    public static removeAnomaliesDatasource = (map: AzureMap, hasAnomaliesLayerMapEvent: boolean, anomalyLayerMouseover?: () => void, anomalyLayerMouseout?: () => void, anomalyPointClickHandler?: (e: void | MapMouseEvent | layer.Layer) => void): void => {
        let existingAnomaliesLayer = map.layers.getLayerById(anomaliesLayerId) as layer.BubbleLayer;
        if (existingAnomaliesLayer) {

            if (hasAnomaliesLayerMapEvent) {
                map.events.remove('mouseover', existingAnomaliesLayer, anomalyLayerMouseover);
                map.events.remove('mouseout', existingAnomaliesLayer, anomalyLayerMouseout);
                map.events.remove('mousedown', existingAnomaliesLayer, anomalyPointClickHandler);
            }

            map.layers.remove(anomaliesLayerId);
        }

        let existingClusterBubbleLayer = map.layers.getLayerById(clusterBubbleLayerId);
        if (existingClusterBubbleLayer) {
            map.layers.remove(clusterBubbleLayerId);
        }

        let existingClusterCountLayer = map.layers.getLayerById(clusterCountLayerId);
        if (existingClusterCountLayer) {
            map.layers.remove(clusterCountLayerId);
        }

        let anomaliesDatasource = map.sources.getById(anomaliesDatasourceId) as source.DataSource;
        if (anomaliesDatasource) {
            anomaliesDatasource.clear();
            map.sources.remove(anomaliesDatasource);
        }
    }

    public static createAnomaliesDatasource = (map: AzureMap, mergedProject: MergedProjectVersion, hasAnomaliesLayerMapEvent: boolean, anomalyLayerMouseover?: () => void, anomalyLayerMouseout?: () => void, anomalyPointClickHandler?: (e: void | MapMouseEvent | layer.Layer) => void, handleAnomalyPointClicked?: (e: void | MapMouseEvent | layer.Layer, mergedProject: MergedProjectVersion) => void): source.DataSource => {
        let anomaliesDatasource = new source.DataSource(anomaliesDatasourceId);
        map.sources.add(anomaliesDatasource);

        let clusterBubbleLayer = new layer.BubbleLayer(anomaliesDatasource, clusterBubbleLayerId, {
            color: styles2.mixedAnomaliesColor,
            radius: 10,
            filter: ['has', 'point_count']
        });

        let clusterCountLayer = new layer.SymbolLayer(anomaliesDatasource, clusterCountLayerId, {
            iconOptions: {
                image: 'none'
            },
            textOptions: {
                textField: ['get', 'point_count_abbreviated'],
                allowOverlap: true,
                size: 16,
                offset: [0, -0.7],
            }
        });

        let anomaliesLayer = RoadsConditionAndScenariosShared.createAnomaliesLayer(anomaliesDatasource, anomaliesLayerId);
        map.layers.add([anomaliesLayer, clusterBubbleLayer, clusterCountLayer]);

        if (hasAnomaliesLayerMapEvent) {
            map.events.add('mouseover', anomaliesLayer, anomalyLayerMouseover);
            map.events.add('mouseout', anomaliesLayer, anomalyLayerMouseout);

            anomalyPointClickHandler = (e: void | MapMouseEvent | layer.Layer) => handleAnomalyPointClicked(e, mergedProject);
            map.events.add('mousedown', anomaliesLayer, anomalyPointClickHandler);
        }

        return anomaliesDatasource;
    }

    public static isShapeVisible = (shape: Shape): boolean => {
        let properties = shape.getProperties();
        let result = properties.strokeColor !== transparentColor && properties.strokeWidth !== 0;
        return result;
    }



    public static showAreaEnlightenedShape = (shapeId: string, datasource: source.DataSource): void => {
        let shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            let props = shape.getProperties();
            props.strokeColor = styles2.enlightenedAreaColor;
            props.strokeWidth = enlightenedAreaWidth;
            shape.setProperties(props);
        }
    }

    public static hideAreaEnlightenedShape = (shapeId: string, datasource: source.DataSource): void => {
        let shape: Shape = datasource.getShapeById(shapeId);
        if (shape) {
            hideShape(shape);
        }
    }

    public static updateSectionShapesColor = (datasource: source.DataSource, activeQualities: QualityModel[]): void => {
        datasource.getShapes().forEach((section: Shape) => {
            let properties = section.getProperties();
            let entityType = properties.EntityType;

            if (entityType === ShapeEntityType.section) {
                let sectionScoreType = properties.sectionScoreType;
                let sectionScore = properties.sectionScore;
                let strokeColor = styles.unfilteredSectionColor;

                let quality = activeQualities.find(x => x.qualityNote && x.qualityNote === sectionScore);
                if (quality && quality.selected) {
                    strokeColor = ScoreTypesColors.get(sectionScoreType);
                }

                if (strokeColor !== properties.strokeColor) {
                    properties.strokeColor = strokeColor;
                    section.setProperties(properties);
                }
            }
        });
    }

    public static getStepVisibleAnomalies = (step: RoadStepViewData, scoringParameters: Map<string, ScoringParameter>, activeAnomalies: Set<string>): Set<string> => {
        let anomalies = new Set<string>();

        if (step.images) {
            step.images.forEach((image) => {
                if (image.imageAnomalies) {
                    image.imageAnomalies.forEach((anomaly) => {
                        if (!anomalies.has(anomaly.labelType) && activeAnomalies.has(anomaly.labelType)) {
                            let scoringParameter = scoringParameters.get(anomaly.labelType);
                            if (anomaly.confidence >= scoringParameter.confidenceThreshold) {
                                anomalies.add(anomaly.labelType);
                            }
                        }
                    });
                }
            });
        }

        return anomalies;
    }

    public static getDisplayedSectionsPositions = (displayedSectionsIds: Set<number>, roadsSections: Map<number, RoadSectionViewData>): data.Position[] => {
        let selectedSectionsPositions: data.Position[] = [];
        displayedSectionsIds.forEach((sectionId) => {
            let roadSection = roadsSections.get(sectionId);
            let pathGeometry = roadSection.pathGeometry;
            pathGeometry.coordinates.forEach((position: data.Position) => {
                selectedSectionsPositions.push(position);
            });
        });

        return selectedSectionsPositions;
    }

    public static setMapCameraFromPosition = (map: AzureMap, displayedSectionsIds: Set<number>, roadsSections: Map<number, RoadSectionViewData>) => {
        let selectedSectionsPositions = RoadsConditionAndScenariosShared.getDisplayedSectionsPositions(displayedSectionsIds, roadsSections);
        setMapCameraFromPositions(map, selectedSectionsPositions);
    }

    public static QualitiesScores = new Map<QualityType, number[]>([
        [QualityType.monitoring, [10, 9, 8]],
        [QualityType.localizedRepair, [7, 6]],
        [QualityType.generalMaintenance, [5, 4]],
        [QualityType.reinforcement, [3, 2]],
        [QualityType.rehabilitation, [1]],
        [QualityType.empty, [null]]
    ]);

    public static getInitialActiveQualities = (): Set<number> => {
        let initialActiveQualities = new Set<number>([]);
        RoadsConditionAndScenariosShared.QualitiesScores.forEach((QualityScores) => {
            QualityScores.forEach((score) => {
                if(score != null)
                initialActiveQualities.add(score);
            });
        });
        //initialActiveQualities.add(null);
        return initialActiveQualities;
    }

    public static SectionsImportanceValues = ["1", "2", "3", "4", "5"];
}